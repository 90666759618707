import { cmsRoles } from "components/CMS/shared/CMSConstants";
import {
  allPossibleCosignerFinancialDocuments,
  allPossibleCosignerPersonalDocuments,
  allPossibleExtraCosignerOtherDocuments,
  allPossibleExtraCosignerPersonalDocuments,
  allPossibleStudentEducationalDocuments,
  allPossibleStudentFinancialDocuments,
  allPossibleStudentPersonalDocuments,
  allPossibleStudentUniversityDocuments,
  camStages,
} from "components/CMS/shared/CMSConstants";
import { UserTypesForDocs } from "lib/utils/constants";

export const getStudentUploadedDocuments = (uploadedDocs) => {
  const {
    studentPersonalDocuments,
    studentEducationalDocuments,
    studentFinancialDocuments,
    studentUniversityDocuments,
    studentOtherDocuments,
    cosignerPersonalDocuments,
    cosignerFinancialDocuments,
    cosignerOtherDocuments,
    extraCosignerPersonalDocuments,
    extraCosignerFinancialDocuments,
    extraCosignerOtherDocuments,
    applicationExtraDocuments,
  } = uploadedDocs;
  const showStudentSection =
    Object.values(studentPersonalDocuments)?.length > 0 ||
    Object.values(studentEducationalDocuments)?.length > 0 ||
    Object.values(studentFinancialDocuments)?.length > 0 ||
    Object.values(studentUniversityDocuments)?.length > 0;
  const showCosignerSection =
    Object.values(cosignerPersonalDocuments)?.length > 0 ||
    Object.values(cosignerFinancialDocuments)?.length > 0 ||
    Object.values(cosignerOtherDocuments)?.length > 0;
  const showExtraCosignerSection =
    Object.values(extraCosignerPersonalDocuments)?.length > 0 ||
    Object.values(extraCosignerFinancialDocuments)?.length > 0 ||
    Object.values(extraCosignerOtherDocuments)?.length > 0;

  return [
    showStudentSection && {
      title: "Student",
      subSections: [
        Object.values(studentPersonalDocuments)?.length > 0 && {
          title: "Personal",
          docs: Object.values(studentPersonalDocuments),
          docType: "studentPersonalDocuments",
        },
        Object.values(studentEducationalDocuments)?.length > 0 && {
          title: "Education",
          docs: Object.values(studentEducationalDocuments),
          docType: "studentEducationalDocuments",
        },
        Object.values(studentFinancialDocuments)?.length > 0 && {
          title: "Financial",
          docs: Object.values(studentFinancialDocuments),
          docType: "studentFinancialDocuments",
        },
        Object.values(studentUniversityDocuments)?.length > 0 && {
          title: "University",
          docs: Object.values(studentUniversityDocuments),
          docType: "studentUniversityDocuments",
        },
        Object.values(studentOtherDocuments)?.length > 0 && {
          title: "Other Documents",
          docs: Object.values(studentOtherDocuments),
          docType: "studentOtherDocuments",
        },
      ],
    },
    showCosignerSection && {
      title: "Cosigner",
      subSections: [
        Object.values(cosignerPersonalDocuments)?.length > 0 && {
          title: "Personal",
          docs: Object.values(cosignerPersonalDocuments),
          docType: "cosignerPersonalDocuments",
        },
        Object.values(cosignerFinancialDocuments)?.length > 0 && {
          title: "Financial",
          docs: Object.values(cosignerFinancialDocuments),
          docType: "cosignerFinancialDocuments",
        },
        Object.values(cosignerOtherDocuments)?.length > 0 && {
          title: "Other Documents",
          docs: Object.values(cosignerOtherDocuments),
          docType: "cosignerOtherDocuments",
        },
      ],
    },
    showExtraCosignerSection && {
      title: "Extra Cosigner",
      subSections: [
        Object.values(extraCosignerPersonalDocuments)?.length > 0 && {
          title: "Personal",
          docs: Object.values(extraCosignerPersonalDocuments),
          docType: "extraCosignerPersonalDocuments",
        },
        Object.values(extraCosignerFinancialDocuments)?.length > 0 && {
          title: "Financial",
          docs: Object.values(extraCosignerFinancialDocuments),
          docType: "extraCosignerFinancialDocuments",
        },
        Object.values(extraCosignerOtherDocuments)?.length > 0 && {
          title: "Other Documents",
          docs: Object.values(extraCosignerOtherDocuments),
          docType: "extraCosignerOtherDocuments",
        },
      ],
    },
    Object.values(applicationExtraDocuments)?.length > 0 && {
      title: "Extra Documents",
      subSections: [
        Object.values(applicationExtraDocuments)?.length > 0 && {
          title: "Extra Documents",
          docs: Object.values(applicationExtraDocuments),
          docType: "applicationExtraDocuments",
        },
      ],
    },
  ];
};

export const getStudentNonUploadedDocuments = (uploadedDocs) => {
  const studentPersonalDocuments = allPossibleStudentPersonalDocuments?.filter(
    (doc) => !uploadedDocs?.studentPersonalDocuments[doc?.keyName]
  );
  const studentEducationalDocuments =
    allPossibleStudentEducationalDocuments?.filter(
      (doc) => !uploadedDocs?.studentEducationalDocuments[doc?.keyName]
    );
  const studentFinancialDocuments =
    allPossibleStudentFinancialDocuments?.filter(
      (doc) => !uploadedDocs?.studentFinancialDocuments[doc?.keyName]
    );
  const studentUniversityDocuments =
    allPossibleStudentUniversityDocuments?.filter(
      (doc) => !uploadedDocs?.studentUniversityDocuments[doc?.keyName]
    );
  const cosignerPersonalDocuments =
    allPossibleCosignerPersonalDocuments?.filter(
      (doc) => !uploadedDocs?.cosignerPersonalDocuments[doc?.keyName]
    );
  const cosignerFinancialDocuments =
    allPossibleCosignerFinancialDocuments?.filter(
      (doc) => !uploadedDocs?.cosignerFinancialDocuments[doc?.keyName]
    );
  const extraCosignerPersonalDocuments =
    allPossibleExtraCosignerPersonalDocuments?.filter(
      (doc) => !uploadedDocs?.extraCosignerPersonalDocuments[doc?.keyName]
    );

  const showStudentSection =
    studentPersonalDocuments?.length > 0 ||
    studentEducationalDocuments?.length > 0 ||
    studentFinancialDocuments?.length > 0 ||
    studentUniversityDocuments?.length > 0;
  const showCosignerSection =
    cosignerPersonalDocuments?.length > 0 ||
    cosignerFinancialDocuments?.length > 0;

  return [
    showStudentSection && {
      title: "Student",
      subSections: [
        studentPersonalDocuments?.length > 0 && {
          title: "Personal",
          docs: studentPersonalDocuments,
          docType: "studentPersonalDocuments",
        },
        studentEducationalDocuments?.length > 0 && {
          title: "Education",
          docs: studentEducationalDocuments,
          docType: "studentEducationalDocuments",
        },
        studentFinancialDocuments?.length > 0 && {
          title: "Financial",
          docs: studentFinancialDocuments,
          docType: "studentFinancialDocuments",
        },
        studentUniversityDocuments?.length > 0 && {
          title: "University",
          docs: studentUniversityDocuments,
          docType: "studentUniversityDocuments",
        },
      ],
      requestCategory: "APPLICANT",
    },
    showCosignerSection && {
      title: "Cosigner",
      subSections: [
        cosignerPersonalDocuments?.length > 0 && {
          title: "Personal",
          docs: cosignerPersonalDocuments,
          docType: "cosignerPersonalDocuments",
        },
        cosignerFinancialDocuments?.length > 0 && {
          title: "Financial",
          docs: cosignerFinancialDocuments,
          docType: "cosignerFinancialDocuments",
        },
      ],
      requestCategory: "COSIGNER",
    },
    extraCosignerPersonalDocuments?.length > 0 && {
      title: "Extra Cosigner",
      subSections: [
        extraCosignerPersonalDocuments?.length > 0 && {
          title: "Personal",
          docs: extraCosignerPersonalDocuments,
          docType: "extraCosignerPersonalDocuments",
        },
        allPossibleExtraCosignerOtherDocuments?.length > 0 && {
          title: "Other",
          docs: allPossibleExtraCosignerOtherDocuments,
          docType: "extraCosignerOtherDocuments",
        },
      ],
      requestCategory: "EXTRA_COSIGNER",
    },
  ];
};

export const generateTabs = (camStage, cmsUserRole) => {
  const tabs = [
    {
      key: "summary",
      label: "Summary",
    },
    {
      key: "cam",
      label: "CAM",
    },
    (camStage === camStages?.CAM_REVIEW ||
      camStage === camStages?.DOCUMENTS_REQUESTED) && {
      key: "documentRequestBucket",
      label: "Document Request Bucket",
    },
    cmsUserRole === cmsRoles?.MANAGER && {
      key: "topUp",
      label: "Top Up",
    },
  ];
  return tabs?.filter((tab) => tab !== false);
};

export const checkIsBureauGeneratedCreditReport = (
  keyName,
  docType,
  personalDetails,
  activeCosignerPersonalDetails,
  extraCosignerPersonalDetails
) => {
  if (
    keyName === "creditReportApplicant" &&
    docType === "studentFinancialDocuments" &&
    personalDetails?.creditReportSource === "BUREAU"
  ) {
    return true;
  } else if (
    keyName === "creditReportCosigner" &&
    docType === "cosignerFinancialDocuments" &&
    activeCosignerPersonalDetails?.creditReportSource === "BUREAU"
  ) {
    return true;
  } else if (
    keyName === "creditReportCosigner" &&
    docType === "extraCosignerFinancialDocuments" &&
    extraCosignerPersonalDetails?.creditReportSource === "BUREAU"
  ) {
    return true;
  } else {
    return false;
  }
};

export const parseUserPersonalDetails = (values) => {
  if (!values) {
    return values;
  }
  const personalDetails = { ...values };
  personalDetails.age = `${personalDetails?.ageYears} Years, ${personalDetails?.ageMonths} Months`;
  delete personalDetails?.ageYears;
  delete personalDetails?.ageMonths;
  personalDetails.assetAndInvestment = personalDetails?.assetAndInvestment?.map(
    (asset, index) => {
      return { ...asset, serialNumber: index + 1 };
    }
  );
  return personalDetails;
};

export const getStudentTopUpDocument = (studentTopUpDocuments) => {
  const {
    topupInvoice = {},
    topupLatestTranscript = {},
    topupLastSemesterReceipt = {},
    topupRequestMail = {},
    topupAddDoc1 = {},
    topupAddDoc2 = {},
    topupAddDoc3 = {},
  } = studentTopUpDocuments ?? {};
  const additionalDocuments = {
    topupAddDoc1,
    topupAddDoc2,
    topupAddDoc3,
  };
  const topupBorrowerAgreement =
    "borrower-agreement-topup" in studentTopUpDocuments
      ? studentTopUpDocuments["borrower-agreement-topup"]
      : {};
  const getDocumentValues = (doc) => {
    const filteredObject = Object.fromEntries(
      Object.entries(doc).filter(
        ([_, value]) => !!value && Object.keys(value).length !== 0
      )
    );
    return Object.values({ ...filteredObject });
  };
  return [
    {
      title: "Student",
      subSections: [
        {
          title: "University",
          docs: getDocumentValues({ topupLatestTranscript }),
          docType: "studentPersonalDocuments",
        },
        {
          title: "Financial",
          docs: getDocumentValues({ topupInvoice, topupLastSemesterReceipt }),
          docType: "studentPersonalDocuments",
        },
        {
          title: "Personal",
          docs: getDocumentValues({ topupRequestMail }),
          docType: "studentPersonalDocuments",
        },
        {
          title: "Top-up Contract",
          docs: getDocumentValues({ topupBorrowerAgreement }),
          docType: "studentPersonalDocuments",
        },
        {
          title: "Additional Document",
          docs: getDocumentValues({ ...additionalDocuments }),
          docType: "studentPersonalDocuments",
        },
      ],
    },
  ];
};

export const generateTabsForRejectionPopUp = (hasExtraCosigner) =>
  [
    {
      key: UserTypesForDocs.APPLICANT,
      label: "Applicant",
    },
    {
      key: UserTypesForDocs.COSIGNER,
      label: "Co-Signer",
    },

    hasExtraCosigner && {
      key: UserTypesForDocs.EXTRA_COSIGNER,
      label: "Extra Co-Signer",
    },
  ].filter((tab) => tab !== false);
