import { BankAccountApprovalStatusTypes } from "components/DisbursementApplicationLeadDetails/BankAccountUpdate/constants";

export const getLabel = (status) => {
  switch (status) {
    case BankAccountApprovalStatusTypes.PENDING:
      return "New Bank Account Update Request";
    case BankAccountApprovalStatusTypes.APPROVED:
      return "Bank Account Details";
    case BankAccountApprovalStatusTypes.REJECTED:
      return "Rejected Bank Account Update Request";
    default:
      return "";
  }
};

export const mutateEditValues = (values) => {
  return {
    ...values,
    bankAccountDetailsId: values.bankAccountId,
  };
};
