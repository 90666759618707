import {
  requestCmsDetails,
  requestReassignApplication,
  requestRunDedupe,
} from "../../../shared/CMSApis";
import { toast } from "react-toastify";
import { all, call, put, takeLatest } from "redux-saga/effects";
import { errorMessages } from "../../../../../lib/utils/constants";
import {
  fetchDedupedApplications,
  fetchOtherApplications,
  fetchSummaryDetails,
  reassignApplication,
  runDedupe,
  updateApplicationSummary,
  updateDedupeApplications,
  updateOtherApplications,
} from "./SummaryContentSlice";
import {
  fetchAllocatedApplicationList,
  updateIsCamAssigned,
} from "../../../CMSLayout/redux/CMSLayoutSlice";
import router from "next/router";
import { UserDetailsTypes } from "../../../shared/CMSConstants";

function* handleFetchSummaryDetails(action) {
  try {
    const { slug } = router.query || {};
    const res = yield call(requestCmsDetails, {
      applicationSlug: slug,
      detailsType: UserDetailsTypes.APPLICATION_SUMMARY,
    });
    if (res?.data?.success) {
      yield all([
        put(updateApplicationSummary(res?.data?.data)),
        put(updateIsCamAssigned(true)),
      ]);
    } else {
      if (res?.data?.message === "This cam is not assigned to you")
        yield put(updateIsCamAssigned(false));
      else
        toast.error(res?.data?.message || errorMessages?.SOMETHING_WENT_WRONG);
    }
  } catch (err) {
    console.error(err);
    toast.error(errorMessages?.FAILED_TO_FETCH);
  }
}

function* handleFetchOtherApplications(action) {
  try {
    const { slug } = router.query || {};
    const res = yield call(requestCmsDetails, {
      applicationSlug: slug,
      detailsType: UserDetailsTypes.OTHER_APPLICATIONS,
    });
    if (res?.data?.success) {
      yield put(updateOtherApplications(res?.data?.data));
    } else {
      if (res?.data?.message === "This cam is not assigned to you")
        yield put(updateIsCamAssigned(false));
      else
        toast.error(res?.data?.message || errorMessages?.SOMETHING_WENT_WRONG);
    }
  } catch (err) {
    console.error(err);
    toast.error(errorMessages?.FAILED_TO_FETCH);
  }
}

function* handleFetchDedupedApplications(action) {
  try {
    const { slug } = router.query || {};
    const res = yield call(requestCmsDetails, {
      applicationSlug: slug,
      detailsType: UserDetailsTypes.DEDUPED_APPLICATIONS,
    });
    if (res?.data?.success) {
      yield put(updateDedupeApplications(res?.data?.data));
    } else {
      if (res?.data?.message === "This cam is not assigned to you")
        yield put(updateIsCamAssigned(false));
      else
        toast.error(res?.data?.message || errorMessages?.SOMETHING_WENT_WRONG);
    }
  } catch (err) {
    console.error(err);
    toast.error(errorMessages?.FAILED_TO_FETCH);
  }
}

function* handleRunDedupe(action) {
  try {
    const res = yield call(requestRunDedupe, action.payload.applicationSlug);
    if (res?.data?.success) {
      toast.success("Dedupe refreshed successfully");
      yield put(
        fetchDedupedApplications({
          applicationSlug: router.query.slug,
          detailsType: UserDetailsTypes.DEDUPED_APPLICATIONS,
        })
      );
    } else {
      toast.error(res?.data?.message || errorMessages?.SOMETHING_WENT_WRONG);
    }
  } catch (err) {
    console.error(err);
    toast.error(errorMessages?.FAILED_TO_FETCH);
  }
}

function* handleReassignApplication(action) {
  try {
    const res = yield call(
      requestReassignApplication,
      action.payload.applicationSlug,
      action.payload.opsUserId
    );
    if (res?.data?.success) {
      toast.success("Application reassigned successfully!");
      yield put(
        fetchSummaryDetails({
          applicationSlug: action.payload.applicationSlug,
          detailsType: UserDetailsTypes.APPLICATION_SUMMARY,
        })
      );
      yield put(
        fetchAllocatedApplicationList({
          casetype: router.query.category?.toUpperCase(),
          pagenumber: 0,
        })
      );
    } else {
      toast.error(res?.data?.message || errorMessages?.SOMETHING_WENT_WRONG);
    }
  } catch (err) {
    console.error(err);
    toast.error(errorMessages?.FAILED_TO_FETCH);
  }
}

function* SummaryContentSaga() {
  yield takeLatest(fetchSummaryDetails, handleFetchSummaryDetails);
  yield takeLatest(fetchOtherApplications, handleFetchOtherApplications);
  yield takeLatest(reassignApplication, handleReassignApplication);
  yield takeLatest(runDedupe, handleRunDedupe);
  yield takeLatest(fetchDedupedApplications, handleFetchDedupedApplications);
}
export default SummaryContentSaga;
