export const columns = [
  {
    title: "Account Number",
    dataIndex: "accountNumber",
    key: "accountNumber",
  },
  {
    title: "Wire Routing no.",
    dataIndex: "routingNumberWire",
    key: "routingNumberWire",
  },
  {
    title: "ACH Routing no.",
    dataIndex: "routingNumberAch",
    key: "routingNumberAch",
  },
  {
    title: "Bank Name",
    dataIndex: "bankName",
    key: "bankName",
  },
  {
    title: "Bank City",
    dataIndex: "bankCity",
    key: "bankCity",
  },
  {
    title: "Bank State",
    dataIndex: "bankState",
    key: "bankState",
  },
  {
    title: "Zip Code",
    dataIndex: "bankZipCode",
    key: "bankZipCode",
  },
];

export const BankAccountApprovalStatusTypes = {
  PENDING: "PENDING",
  APPROVED: "APPROVED",
  REJECTED: "REJECTED",
};

export const bankAccountStatusKeyMapper = {
  pendingBankAccountDetails: BankAccountApprovalStatusTypes.PENDING,
  approvedBankAccountDetails: BankAccountApprovalStatusTypes.APPROVED,
  rejectedBankAccountDetails: BankAccountApprovalStatusTypes.REJECTED,
};
