import {
  requestResetFeeDetailsForInschool,
  requestResetFeeDetailsForRefi,
} from "components/Form/OfferDetails/OfferDetailsApi";

export const resetFeeDetails = async (
  data,
  isRefi = false,
  errorCallBack = () => {},
  successCallBack = () => {}
) => {
  try {
    const requestResetFeeDetails = isRefi
      ? requestResetFeeDetailsForRefi
      : requestResetFeeDetailsForInschool;
    const res = await requestResetFeeDetails(data);
    if (!res?.data?.success) {
      errorCallBack(res?.data?.message);
    } else {
      successCallBack(res?.data);
    }
  } catch (error) {
    console.error(error);
    errorCallBack(res?.data?.message);
  }
};
