import { cloneDeep } from "lodash";
import { StudentHighestEducation, ValidationFieldTypes } from "../../../../../../shared/CMSConstants";
import { specificFieldValidation } from "components/CMS/shared/FieldValidations";

export const mutateAcademicDetailsValues = (values) => {
  const { highestEducation, diplomaCollegeEducationDetails } = values || {};
  const mutatedValues = cloneDeep(values);
  if (values?.highestEducation === StudentHighestEducation.PG) {
    mutatedValues.pgCollegeEducationDetails.hasDegree = true;
    mutatedValues.ugCollegeEducationDetails.hasDegree = true;
  }
  if (values?.highestEducation === StudentHighestEducation.UG) {
    mutatedValues.ugCollegeEducationDetails.hasDegree = true;
    mutatedValues.pgCollegeEducationDetails.hasDegree = false;
  }
  mutatedValues.diplomaCollegeEducationDetails.hasDegree =
    !!values?.diplomaCollegeEducationDetails.gpaScore ||
    !!values?.diplomaCollegeEducationDetails.collegeName ||
    !!values?.diplomaCollegeEducationDetails.courseName ||
    !!values?.diplomaCollegeEducationDetails.backlogCount ||
    !!values?.diplomaCollegeEducationDetails.gapYearCount ||
    !!values?.diplomaCollegeEducationDetails.passingYear;
  return mutatedValues;
};

export const formatSeasons = (item) => ({
  label: item.split("_").join(" ").toUpperCase(),
  value: item,
});

export const formatOptions = (item) => ({
  label: item.name,
  value: item.id,
});

export const academicDetailsValidator = (values) => {
  const errors = {
    ugCollegeEducationDetails: {
      gpaScore: specificFieldValidation(
        values?.ugCollegeEducationDetails?.gpaScore,
        ValidationFieldTypes?.GPA,
        values?.ugCollegeEducationDetails?.gpaBase
      ),
    },
    pgCollegeEducationDetails: {
      gpaScore: specificFieldValidation(
        values?.pgCollegeEducationDetails?.gpaScore,
        ValidationFieldTypes?.GPA,
        values?.pgCollegeEducationDetails?.gpaBase
      ),
    },
    diplomaCollegeEducationDetails: {
      gpaScore: specificFieldValidation(
        values?.diplomaCollegeEducationDetails?.gpaScore,
        ValidationFieldTypes?.GPA,
        values?.diplomaCollegeEducationDetails?.gpaBase
      ),
    },
  };
  return errors;
};
