import { toast } from "react-toastify";
import { all, call, put, takeLatest } from "redux-saga/effects";
import { errorMessages } from "../../../../lib/utils/constants";
import {
  requestAllocatedApplicationList,
  requestApplicationSeenStatusUpdate,
  requestCMSInitialDetails,
  requestSearchAllocatedApplication,
  requestTeamMembers,
  requestFilterApplications,
  requestMarkMemberAvailability,
} from "../../shared/CMSApis";
import {
  fetchAllocatedApplicationList,
  fetchCMSInitialDetails,
  fetchTeamMembers,
  searchAllocatedApplications,
  updateAllocatedApplicationList,
  updateApplicationSeenStatus,
  updateAvailableViewCategories,
  updateCurrentPage,
  updateSearchQuery,
  updateTeamMembers,
  updateTotalPages,
  updateIsCamAssigned,
  saveMemberAvailability,
  filterApplications,
  updateIsFilterApplied,
  updateFilterConditions,
} from "./CMSLayoutSlice";

function* handleFetchAllocatedApplicationList(action) {
  yield put(updateCurrentPage(action?.payload?.pagenumber));
  try {
    const res = yield call(
      requestAllocatedApplicationList,
      action?.payload?.casetype,
      action?.payload?.pagenumber
    );
    if (res?.data?.success) {
      yield all([
        put(
          updateAllocatedApplicationList(res?.data?.data?.applicationDetails)
        ),
        put(updateTotalPages(res?.data?.data?.totalPages)),
      ]);
    } else {
      toast.error(res?.data?.message || errorMessages?.SOMETHING_WENT_WRONG);
    }
  } catch (err) {
    console.error(err);
    toast.error(errorMessages?.FAILED_TO_FETCH);
  }
}

function* handleSearchAllocatedApplications(action) {
  yield all([
    put(updateCurrentPage(action?.payload?.pagenumber)),
    put(updateSearchQuery(action?.payload?.query)),
  ]);
  try {
    const res = yield call(
      requestSearchAllocatedApplication,
      action?.payload?.query,
      action?.payload?.pagenumber
    );
    if (res?.data?.success) {
      yield all([
        put(
          updateAllocatedApplicationList(res?.data?.data?.applicationDetails)
        ),
        put(updateTotalPages(res?.data?.data?.totalPages)),
      ]);
    } else {
      toast.error(res?.data?.message || errorMessages?.SOMETHING_WENT_WRONG);
    }
  } catch (error) {
    console.error(error);
    toast.error(errorMessages?.FAILED_TO_FETCH);
  }
}

function* handleFilterApplications(action) {
  yield put(updateCurrentPage(action?.payload?.pagenumber));
  try {
    const res = yield call(requestFilterApplications, {
      ...action?.payload,
    });
    const {
      applicantPdStatus,
      cosignerPdStatus,
      fromDate,
      stage,
      toDate,
      opsDataId,
    } = action?.payload?.data || {};
    if (res?.data?.success) {
      yield all([
        applicantPdStatus ||
        cosignerPdStatus ||
        fromDate ||
        stage ||
        toDate ||
        opsDataId
          ? put(updateIsFilterApplied(true))
          : put(updateIsFilterApplied(false)),
        put(
          updateAllocatedApplicationList(res?.data?.data?.applicationDetails)
        ),
        put(updateTotalPages(res?.data?.data?.totalPages)),
        put(updateFilterConditions(action?.payload?.data)),
      ]);
    } else {
      toast.error(res?.data?.message || errorMessages?.SOMETHING_WENT_WRONG);
    }
  } catch (error) {
    console.error(error);
    toast.error(errorMessages?.FAILED_TO_FETCH);
  }
}

function* handleUpdateApplicationSeenStatus(action) {
  try {
    const res = yield call(
      requestApplicationSeenStatusUpdate,
      action?.payload?.slug
    );
    if (!res?.data?.success) {
      if (res?.data?.message === "This cam is not assigned to you") {
        yield put(updateIsCamAssigned(false));
      } else {
        toast.error(res?.data?.message || errorMessages?.SOMETHING_WENT_WRONG);
      }
    } else {
      yield put(updateIsCamAssigned(true));
    }
  } catch (error) {
    console.error(error);
    toast.error(errorMessages?.FAILED_TO_FETCH);
  }
}

function* handleFetchCMSInitialDetails(action) {
  yield all([
    put(updateCurrentPage(action?.payload?.pagenumber)),
    put(updateSearchQuery(action?.payload?.query)),
  ]);
  try {
    const res = yield call(
      requestCMSInitialDetails,
      action?.payload?.casetype,
      action?.payload?.pagenumber,
      action?.payload?.query
    );
    if (res?.status === 200) {
      yield all([
        put(updateAvailableViewCategories(res?.data?.pageConfigs)),
        put(updateAllocatedApplicationList(res?.data?.applicationDetails)),
        put(updateTotalPages(res?.data?.totalPages)),
      ]);
    } else {
      toast.error(res?.data?.message || errorMessages?.SOMETHING_WENT_WRONG);
    }
  } catch (error) {
    console.error(error);
    toast.error(errorMessages?.FAILED_TO_FETCH);
  }
}

function* handleFetchTeamMembers() {
  try {
    const res = yield call(requestTeamMembers);
    if (res?.data?.success) {
      yield put(updateTeamMembers(res?.data?.data));
    } else {
      toast.error(res?.data?.message || errorMessages?.SOMETHING_WENT_WRONG);
    }
  } catch (error) {
    console.error(error);
    toast.error(errorMessages?.FAILED_TO_FETCH);
  }
}

function* handleSaveMemberAvailability(action) {
  try {
    const res = yield call(requestMarkMemberAvailability, {
      ...action.payload,
    });
    if (res?.data?.success) {
      yield put(fetchTeamMembers());
      toast.success("Availability updated successfully");
    } else {
      toast.error(res?.data?.message || errorMessages?.SOMETHING_WENT_WRONG);
    }
  } catch (error) {
    console.error(error);
    toast.error(errorMessages?.FAILED_TO_FETCH);
  }
}

function* CMSLayoutSaga() {
  yield takeLatest(
    fetchAllocatedApplicationList,
    handleFetchAllocatedApplicationList
  );
  yield takeLatest(
    searchAllocatedApplications,
    handleSearchAllocatedApplications
  );
  yield takeLatest(
    updateApplicationSeenStatus,
    handleUpdateApplicationSeenStatus
  );
  yield takeLatest(fetchCMSInitialDetails, handleFetchCMSInitialDetails);
  yield takeLatest(filterApplications, handleFilterApplications);
  yield takeLatest(fetchTeamMembers, handleFetchTeamMembers);
  yield takeLatest(saveMemberAvailability, handleSaveMemberAvailability);
}
export default CMSLayoutSaga;
