import { createAction, createSlice } from "@reduxjs/toolkit";

export const rejectApplication = createAction("camSection/rejectApplication");
export const approveApplication = createAction("camSection/approveApplication");
export const sendApplicationForReview = createAction(
  "camSection/sendApplicationForReview"
);
export const editApplication = createAction("camSection/editApplication");
export const downloadCam = createAction("camSection/downloadCam");
export const fetchMaxApprovalLimit = createAction(
  "camSection/fetchMaxApprovalLimit"
);
export const fetchApprovalCTAText = createAction(
  "camSection/fetchApprovalCTAText"
);
export const fetchExtraCosignerAttempt = createAction(
  "camSection/fetchExtraCosignerAttempt"
);

const camSectionSlice = createSlice({
  name: "camSection",
  initialState: {
    isViewCam: false,
    isDownloadingCam: false,
    maxApprovalLimit: null,
    approvalCTAText: "",
    extraCosignerExists: false,
  },
  reducers: {
    updateIsViewCam(state, action) {
      const isViewCam = action.payload;
      return { ...state, isViewCam };
    },
    updateIsDownloadingCam(state, action) {
      const isDownloadingCam = action.payload;
      return { ...state, isDownloadingCam };
    },
    updateMaxApprovalLimit(state, action) {
      return { ...state, maxApprovalLimit: action?.payload };
    },
    updateApprovalCTAText(state, action) {
      return { ...state, approvalCTAText: action?.payload };
    },
    updateExtraCosignerExists(state, action) {
      return { ...state, extraCosignerExists: action?.payload };
    },
  },
});

export const {
  updateIsViewCam,
  updateIsDownloadingCam,
  updateMaxApprovalLimit,
  updateApprovalCTAText,
  updateExtraCosignerExists,
} = camSectionSlice.actions;
export default camSectionSlice.reducer;
