import { createAction, createSlice } from "@reduxjs/toolkit";

const initialState = {
  extraDetails: null,
  UEOfferDetails: null,
  eligibilityDetails: null,
  updatedOfferDetails: null,
  personalDiscussionDetails: null,
  approvalDetails: null,
  ofacDetails: null,
};

export const fetchOtherDetails = createAction("otherDetails/studentDetails");
export const saveOtherDetails = createAction("otherDetails/saveStudentDetails");

const otherDetailsSlice = createSlice({
  name: "otherDetails",
  initialState,
  reducers: {
    updateExtraDetails(state, action) {
      const extraDetails = action.payload;
      return { ...state, extraDetails };
    },
    updateUEOfferDetails(state, action) {
      const UEOfferDetails = action.payload;
      return { ...state, UEOfferDetails };
    },
    updateEligibilityDetails(state, action) {
      const eligibilityDetails = action.payload;
      return { ...state, eligibilityDetails };
    },
    updateUpdatedOfferDetails(state, action) {
      const updatedOfferDetails = action.payload;
      return { ...state, updatedOfferDetails };
    },
    updatePersonalDiscussionDetails(state, action) {
      const personalDiscussionDetails = action.payload;
      return { ...state, personalDiscussionDetails };
    },
    updateOfacDetails(state, action) {
      const ofacDetails = action.payload;
      return { ...state, ofacDetails };
    },
    updateApprovalDetails(state, action) {
      const approvalDetails = action.payload;
      return { ...state, approvalDetails };
    },
  },
});

export const {
  updateExtraDetails,
  updateUEOfferDetails,
  updateEligibilityDetails,
  updateUpdatedOfferDetails,
  updatePersonalDiscussionDetails,
  updateApprovalDetails,
  updateOfacDetails,
} = otherDetailsSlice.actions;
export default otherDetailsSlice.reducer;
