import { createAction, createSlice } from "@reduxjs/toolkit";

const initialState = {
  availableViewCategories: [],
  allocatedApplicationList: [],
  totalPages: null,
  searchQuery: null,
  navBarHeight: 0,
  searchBarHeight: 0,
  contentHeaderHeight: 0,
  loading: false,
  currentPage: 0,
  teamMembers: null,
  cmsUserRole: null,
  isCamAssigned: false,
  showFiltersFormPopUp: false,
  isFilterApplied: false,
  filterConditions: null,
};

export const fetchAllocatedApplicationList = createAction(
  "cmsLayout/allocatedApplicationList"
);
export const searchAllocatedApplications = createAction(
  "cmsLayout/searchAllocatedApplication"
);
export const filterApplications = createAction("cmsLayout/filterApplications");

export const updateApplicationSeenStatus = createAction(
  "cmsLayout/updateApplicationSeenStatus"
);
export const fetchCMSInitialDetails = createAction(
  "cmsLayout/fetchInitialDetails"
);
export const fetchTeamMembers = createAction("cmsLayout/fetchTeamMembers");

export const saveMemberAvailability = createAction(
  "cmsLayout/saveMemberAvailability"
);

const cmsLayoutSlice = createSlice({
  name: "cmsLayout",
  initialState,
  reducers: {
    updateNavBarHeight(state, action) {
      const navBarHeight = action.payload;
      return { ...state, navBarHeight };
    },
    updateSearchBarHeight(state, action) {
      const searchBarHeight = action.payload;
      return { ...state, searchBarHeight };
    },
    updateContentHeaderHeight(state, action) {
      const contentHeaderHeight = action.payload;
      return { ...state, contentHeaderHeight };
    },
    updateAllocatedApplicationList(state, action) {
      return { ...state, allocatedApplicationList: action?.payload };
    },
    updateAvailableViewCategories(state, action) {
      return { ...state, availableViewCategories: action?.payload };
    },
    updateTotalPages(state, action) {
      return { ...state, totalPages: action?.payload };
    },
    updateSearchQuery(state, action) {
      return { ...state, searchQuery: action?.payload };
    },
    updateLoadingStatus(state, action) {
      return { ...state, loading: action?.payload };
    },
    updateCurrentPage(state, action) {
      return { ...state, currentPage: action?.payload };
    },
    updateTeamMembers(state, action) {
      return { ...state, teamMembers: action?.payload };
    },
    updateCmsUserRole(state, action) {
      return { ...state, cmsUserRole: action?.payload };
    },
    updateIsCamAssigned(state, action) {
      return { ...state, isCamAssigned: action?.payload };
    },
    updateShowFiltersFormPopUp(state, action) {
      return { ...state, showFiltersFormPopUp: action?.payload };
    },
    updateIsFilterApplied(state, action) {
      return { ...state, isFilterApplied: action?.payload };
    },
    updateFilterConditions(state, action) {
      return { ...state, filterConditions: action?.payload };
    },
  },
});

export const {
  updateNavBarHeight,
  updateSearchBarHeight,
  updateContentHeaderHeight,
  updateAllocatedApplicationList,
  updateAvailableViewCategories,
  updateTotalPages,
  updateSearchQuery,
  updateLoadingStatus,
  updateCurrentPage,
  updateTeamMembers,
  updateCmsUserRole,
  updateFilterConditions,
  updateIsCamAssigned,
  updateShowFiltersFormPopUp,
  updateIsFilterApplied,
} = cmsLayoutSlice.actions;
export default cmsLayoutSlice.reducer;
