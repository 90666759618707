import { createAction, createSlice } from "@reduxjs/toolkit";

const initialState = {
  isEditTopUp: true,
  topUpDetails: {},
  isLoading: true,
};

export const saveTopUpDetails = createAction(
  "studentTopUpDetails/saveTopUpDetails"
);

export const fetchStudentTopUpDetails = createAction(
  "studentTopUpDetails/fetchStudentTopUpDetails"
);

export const approveStudentTopUpRequest = createAction(
  "studentTopUpDetails/approveStudentTopUpRequest"
);

const topUpSlice = createSlice({
  name: "studentTopUpDetails",
  initialState,
  reducers: {
    updateTopUpDetails(state, action) {
      const topUpDetails = action.payload;
      return { ...state, topUpDetails };
    },
    updateIsEditTopUp(state, action) {
      const isEditTopUp = action.payload;
      return { ...state, isEditTopUp };
    },
    updateIsLoading(state, action) {
      const isLoading = action.payload;
      return { ...state, isLoading };
    },
  },
});

export const { updateTopUpDetails, updateIsEditTopUp, updateIsLoading } = topUpSlice.actions;
export default topUpSlice.reducer;
