import App from "next/app";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { wrapper } from "../redux/store.js";
import "../shared/common.css";
import axios from "axios";
import { isBrowserClient } from "lib/utils/helper.js";
import {
  ThemeProvider,
  StyledEngineProvider,
  createTheme,
} from "@mui/material/styles";
import { Provider } from "react-redux";

const theme = createTheme();

if (isBrowserClient()) {
  axios.interceptors.response.use(
    function (response) {
      return response;
    },
    function (error) {
      const { status } = error.response || {};
      if (status === 401 && window?.location?.pathname !== "/") {
        window.open("/api/logout", "_self");
      } else {
        return Promise.reject(error);
      }
    }
  );
}
const MyApp = ({ Component, pageProps }) => {
  const { store } = wrapper.useWrappedStore(pageProps);
  return (
    <Provider store={store}>
      <StyledEngineProvider injectFirst>
        <ThemeProvider theme={theme}>
          <Component {...pageProps} />
          <ToastContainer
            position="top-right"
            autoClose={5000}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnHover
            className="max-w-[400px] w-fit"
          />
        </ThemeProvider>
      </StyledEngineProvider>
    </Provider>
  );
};

export default MyApp;
