import { AuthToken } from "../auth_token";
import { isArray } from "lodash";
import { getTokenCookie } from "./auth-cookies-new";
import { CreditFormDisplayFields, UserTypes } from "./constants";
import { roleToViewCallRecordings } from "DataMapper/roles";

export const getDocuments = (documents) => {
  return documents.reduce((a, c) => {
    if (isArray(a[c.keyName])) {
      a[c.keyName].push(c);
    } else {
      a[c.keyName] = [c];
    }
    return a;
  }, {});
};

export const getDocumentsById = (documents) => {
  return documents?.reduce((a, c) => {
    if (isArray(a[c.id])) {
      a[c.id].push(c);
    } else {
      a[c.id] = [c];
    }
    return a;
  }, {});
};

export const parseBankDocuments = (documentsArray) => {
  return documentsArray.reduce((a, c) => {
    if (isArray(a[c.documentName])) {
      // if it is a multiple doc upload
      a[c.documentName].push(c);
    } else {
      // single doc upload
      c.name = c.url.split("/")[c.url.split("/").length - 1];
      c.keyName = c.documentName;
      a[c.documentName] = [c];
    }
    return a;
  }, {});
};

export function isValidDate(year, month, day) {
  const studentEnteredDate = new Date(year, month - 1, day);
  if (
    studentEnteredDate.getFullYear() === year &&
    year.toString().length === 4 &&
    studentEnteredDate.getMonth() === month - 1 &&
    studentEnteredDate.getDate() === day
  ) {
    return true;
  }
  return false;
}

export const parseCreditData = (creditDetails) => {
  const userData = { type: "Profile" };
  const cosignerData = { type: "Cosigner" };
  const extraCosignerData = { type: "ExtraCosigner" };
  Object.values(UserTypes).map((data) => {
    if (data === "applicant") {
      CreditFormDisplayFields.map((field) => {
        userData[field] = !isUndefinedOrNull(creditDetails[`${data}${field}`])
          ? creditDetails[`${data}${field}`]
          : "";
      });
    } else if (data === "activeCosigner") {
      CreditFormDisplayFields.map((field) => {
        cosignerData[field] = !isUndefinedOrNull(
          creditDetails[`${data}${field}`]
        )
          ? creditDetails[`${data}${field}`]
          : "";
      });
    } else {
      CreditFormDisplayFields.map((field) => {
        extraCosignerData[field] = !isUndefinedOrNull(
          creditDetails[`extraCosigner${field}`]
        )
          ? creditDetails[`extraCosigner${field}`]
          : "";
      });
    }
  });
  return [userData, cosignerData, extraCosignerData];
};

export const getAbsoluteDifference = (a, b) => Math.abs(a - b);
export const convertCamelcaseStringToWords = (camelcaseString) => {
  const result = camelcaseString.replace(/([A-Z])/g, " $1");
  return result.charAt(0).toUpperCase() + result.slice(1);
};
export const isMaxTwoDecimals = (number) => {
  if (!isNaN(number)) {
    let decimalCount = 0;
    const strValue = number.toString();
    if (strValue.split(".")[1]) {
      decimalCount = strValue.split(".")[1].length;
    }
    if (decimalCount > 2) return false;
    else return true;
  }
};

export const getCmsUserRole = () => {
  const { decodedToken } = new AuthToken(getTokenCookie(null)) || "";
  const role = decodedToken?.user_id?.roles
    ?.find((element) => {
      if (element.includes("cms")) {
        return true;
      }
    })
    ?.split(":")
    ?.pop();
  return role;
};

export const checkIfUserHasCallsRole = () => {
  const { decodedToken } = new AuthToken(getTokenCookie(null)) || "";
  const role = decodedToken?.user_id?.roles?.find(
    (element) => element === roleToViewCallRecordings
  );
  return !!role;
};

export const roundToTwoDecimals = (num) =>
  Math.round((num + Number.EPSILON) * 100) / 100;

export const isUndefinedOrNull = (value) => {
  return [undefined, null].includes(value);
};

export const fileFormatChecker = (fileName, supportedFormats) => {
  if (!fileName) return false;
  const namesArr = fileName?.split(".");
  const fileFormat = namesArr[namesArr.length - 1];
  return supportedFormats.includes(fileFormat.toLowerCase());
};

export function isFutureDate(year, month, day) {
  const presentDate = new Date();
  const currentYear = presentDate.getFullYear();
  const currentMonth = presentDate.getMonth() + 1;
  const currentDate = presentDate.getDate();
  if (
    year > currentYear ||
    (year === currentYear && month > currentMonth) ||
    (year === currentYear && month === currentMonth && day > currentDate)
  ) {
    return true;
  }
  return false;
}

export const validateForNumber = (key) =>
  !/[\d\.]|Delete|Backspace|Tab|[vV]|[cC]|Command|[aA]|Control/.test(key);

export const copyToClipboard = (text) => {
  try {
    navigator.clipboard.writeText(text);
  } catch (error) {
    console.error(error);
  }
};

export const isJson = (str) => {
  try {
    JSON.parse(str);
  } catch (e) {
    return false;
  }
  return true;
};

export const containsForbidden = (input) => /forbidden/i.test(input);

export const isBrowserClient = () => typeof window !== "undefined";

export const isFalsy = (value) =>
  value === `` ||
  value === null ||
  value === undefined ||
  (Array.isArray(value) && value.length === 0) ||
  (typeof value === `object` && Object.keys(value).length === 0);
