import axios from "axios";

export const requestTopUpDetails = (slug) =>
  axios.request({
    method: "GET",
    url: `/api/route?path=topup/fetchTopupApplication/${slug}`,
  });

export const saveAndUpdateTopUpDetails = ({ data, slug }) =>
  axios.request({
    method: "POST",
    url: `/api/route?path=topup/updateTopupApplication/${slug}`,
    data,
  });

export const requestTopUpApproval = (slug) =>
  axios.request({
    method: "PUT",
    url: `/api/route?path=topup/approve/${slug}`,
  });
