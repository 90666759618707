import { createAction, createSlice } from "@reduxjs/toolkit";

const initialState = {
  applicationSummary: null,
  otherApplications: null,
  dedupedApplications: null,
  currentStage: "",
};

export const fetchSummaryDetails = createAction("summary/fetchSummaryDetails");

export const fetchOtherApplications = createAction(
  "summary/fetchOtherApplications"
);
export const fetchDedupedApplications = createAction(
  "summary/fetchDedupedApplications"
);

export const reassignApplication = createAction("summary/reassignApplication");

export const runDedupe = createAction("summary/runDedupe");

const cmsLayoutSlice = createSlice({
  name: "summary",
  initialState,
  reducers: {
    updateApplicationSummary(state, action) {
      const applicationSummary = action.payload;
      return { ...state, applicationSummary };
    },
    updateOtherApplications(state, action) {
      const otherApplications = action.payload;
      return { ...state, otherApplications };
    },
    updateDedupeApplications(state, action) {
      const dedupedApplications = action.payload;
      return { ...state, dedupedApplications };
    },
    updateCurrentStage(state, action) {
      const currentStage = action.payload;
      return { ...state, currentStage };
    },
  },
});

export const {
  updateApplicationSummary,
  updateOtherApplications,
  updateCurrentStage,
  updateDedupeApplications,
} = cmsLayoutSlice.actions;
export default cmsLayoutSlice.reducer;
