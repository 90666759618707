import {
  requestCmsDetails,
  requestSaveCmsDetails,
} from "../../../../../../shared/CMSApis";
import { toast } from "react-toastify";
import { call, put, takeEvery, takeLatest } from "redux-saga/effects";
import {
  fetchCosignerDetails,
  saveCosignerDetails,
  updateAdditionalComments,
  updateAddressDetails,
  updateAverageBankBalanceDetails,
  updateExtraCosignerAdditionalComments,
  updateExtraCosignerAddressDetails,
  updateExtraCosignerPersonalDetails,
  updateExtraCosignerWorkExperienceDetails,
  updatePersonalDetails,
  updateWorkExperienceDetails,
  updateFamilyExposureForCosigner,
  updateFamilyExposureForExtraCosigner,
} from "./cosignerDetailsSlice";
import { errorMessages } from "../../../../../../../../lib/utils/constants";
import router from "next/router";
import {
  UserDetailsTypes,
  UserTypes,
} from "../../../../../../shared/CMSConstants";
import { parseUserPersonalDetails } from "../../../../../../shared/CMSUtils";

function* handleFetchCosignerDetails(action) {
  try {
    const res = yield call(requestCmsDetails, {
      applicationSlug: router.query.slug,
      ...action.payload,
    });
    const { detailsType, userType } = action.payload || {};
    if (res?.data?.success) {
      switch (detailsType) {
        case UserDetailsTypes.PERSONAL_DETAILS:
          const parsedPersonalDetails = parseUserPersonalDetails(
            res?.data?.data
          );
          userType === UserTypes.EXTRA_COSIGNER
            ? yield put(
                updateExtraCosignerPersonalDetails(parsedPersonalDetails)
              )
            : yield put(updatePersonalDetails(parsedPersonalDetails));
          break;
        case UserDetailsTypes.ADDRESS_DETAILS:
          userType === UserTypes.EXTRA_COSIGNER
            ? yield put(updateExtraCosignerAddressDetails(res?.data?.data))
            : yield put(updateAddressDetails(res?.data?.data));
          break;
        case UserDetailsTypes.WORK_DETAILS:
          userType === UserTypes.EXTRA_COSIGNER
            ? yield put(
                updateExtraCosignerWorkExperienceDetails(res?.data?.data)
              )
            : yield put(updateWorkExperienceDetails(res?.data?.data));
          break;
        case UserDetailsTypes.ADDITIONAL_COMMENTS:
          userType === UserTypes.EXTRA_COSIGNER
            ? yield put(updateExtraCosignerAdditionalComments(res?.data?.data))
            : yield put(updateAdditionalComments(res?.data?.data));
          break;
        case UserDetailsTypes.FAMILY_EXPOSURE:
          userType === UserTypes.EXTRA_COSIGNER
            ? yield put(updateFamilyExposureForExtraCosigner(res?.data?.data))
            : yield put(updateFamilyExposureForCosigner(res?.data?.data));
          break;
        case UserDetailsTypes.AVERAGE_BANK_BALANCE:
          yield put(updateAverageBankBalanceDetails(res?.data?.data));
          break;
      }
    } else {
      toast.error(res?.data?.message || errorMessages?.SOMETHING_WENT_WRONG);
    }
  } catch (err) {
    console.error(err);
    toast.error(errorMessages?.FAILED_TO_FETCH);
  }
}

function* handleSaveCosignerDetails(action) {
  try {
    const res = yield call(requestSaveCmsDetails, {
      applicationSlug: router.query.slug,
      ...action.payload,
    });
    const { detailsType, data, userType } = action.payload || null;
    if (res?.data?.success) {
      toast.success("Saved Successfully");
      switch (detailsType) {
        case UserDetailsTypes.AVERAGE_BANK_BALANCE:
          yield put(updateAverageBankBalanceDetails(data));
          break;
        case UserDetailsTypes.PERSONAL_DETAILS:
          const parsedPersonalDetails = parseUserPersonalDetails(
            res?.data?.data
          );
          userType === UserTypes.EXTRA_COSIGNER
            ? yield put(
                updateExtraCosignerPersonalDetails(parsedPersonalDetails)
              )
            : yield put(updatePersonalDetails(parsedPersonalDetails));
          break;
      }
    } else {
      toast.error(res?.data?.message || errorMessages?.SOMETHING_WENT_WRONG);
    }
  } catch (err) {
    console.error(err);
    toast.error(errorMessages?.FAILED_TO_FETCH);
  }
}

function* CosignerDetailsSaga() {
  yield takeEvery(fetchCosignerDetails, handleFetchCosignerDetails);
  yield takeLatest(saveCosignerDetails, handleSaveCosignerDetails);
}

export default CosignerDetailsSaga;
