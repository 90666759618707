import { createAction, createSlice } from "@reduxjs/toolkit";

const initialState = {
  activeViewDocument: {},
  documentBlobUrl: [],
  activeViewDocumentCategory: null,
  activeCategoryDocuments: [],
  initialPage: 0,
  totalImagesCount: 0,
  rotateScale: 0,
  zoomScale: 1,
  version: 1,
  showDocumentFullScreenModal: false,
};

export const fetchDocumentBlobUrl = createAction(
  "documentSection/fetchDocumentBlobUrl"
);

export const fetchUserDocuments = createAction(
  "documentSection/fetchUserDocuments"
);

const documentSectionSlice = createSlice({
  name: "documentSection",
  initialState,
  reducers: {
    updateDocumentViewStatus(state, action) {
      return {
        ...state,
        activeViewDocument: action?.payload?.activeViewDocument,
        initialPage: action?.payload?.initialPage,
        totalImagesCount: action?.payload?.totalImagesCount,
        activeViewDocumentCategory: action?.payload?.activeViewDocumentCategory,
        documentBlobUrl: [],
        activeCategoryDocuments: action?.payload?.activeCategoryDocuments,
      };
    },
    updateDocumentBlobUrl(state, action) {
      return {
        ...state,
        documentBlobUrl: [
          ...state?.documentBlobUrl,
          {
            blobUrl: action?.payload?.blobUrl,
            fileType: action?.payload?.fileType,
            password: action?.payload?.password
          },
        ],
      };
    },
    updateDocumentZoomScale(state, action) {
      return {
        ...state,
        zoomScale: action?.payload,
      };
    },
    updateDocumentRotateScale(state, action) {
      return {
        ...state,
        rotateScale: action?.payload,
      };
    },
    updateDocumentVersion(state, action) {
      return {
        ...state,
        version: action?.payload,
      };
    },
    updateShowDocumentFullScreenModal(state, action) {
      return { ...state, showDocumentFullScreenModal: action?.payload };
    },
  },
});

export const {
  updateDocumentViewStatus,
  updateDocumentBlobUrl,
  updateDocumentZoomScale,
  updateDocumentRotateScale,
  updateDocumentVersion,
  updateShowDocumentFullScreenModal,
} = documentSectionSlice.actions;
export default documentSectionSlice.reducer;
