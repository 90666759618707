import axios from "axios";

export const requestFetchReuploadDocuments = (slug) => {
  return axios.request({
    method: "GET",
    url: `/api/route?path=camdetails/documents/${slug}`,
  });
};

export const requestSendDocumentsReuploadToStudent = (slug) => {
  return axios.request({
    method: "PUT",
    url: `/api/route?path=camdetails/sendRequestForDocuments/${slug}`,
    data: { comments: null },
  });
};

export const requestRemoveRequestedDocument = (slug, requestId) => {
  return axios.request({
    method: "DELETE",
    url: `/api/route?path=camdetails/documents/${slug}/${requestId}`,
  });
};

export const requestCancelDocumentRequest = (slug, data) => {
  return axios.request({
    method: "POST",
    url: `/api/route?path=camdetails/deleteDocumentRequest/${slug}`,
    data,
  });
};

export const requestAddToDocumentRequestBucket = (slug, data) => {
  return axios.request({
    method: "PUT",
    url: `/api/route?path=camdetails/documents/${slug}`,
    data: data,
  });
};
