import { combineReducers } from "@reduxjs/toolkit";
import StudentDetailsReducer from "./CMSContent/CAMTabContent/CAMSection/Forms/StudentDetails/redux/studentDetailsSlice";
import CosignerDetailsReducer from "./CMSContent/CAMTabContent/CAMSection/Forms/CosignerDetails/redux/cosignerDetailsSlice";
import CMSLayoutReducer from "./CMSLayout/redux/CMSLayoutSlice";
import SummaryContentSlice from "./CMSContent/SummaryTabContent/redux/SummaryContentSlice";
import CAMSectionSlice from "./CMSContent/CAMTabContent/CAMSection/redux/CAMSectionSlice";
import otherDetailsSlice from "./CMSContent/CAMTabContent/CAMSection/Forms/OtherDetails/redux/otherDetailsSlice";
import DocumentRequestReducer from "./CMSContent/DocumentRequestTabContent/redux/documentRequestSlice";
import DocumentSectionReducer from "./CMSContent/CAMTabContent/DocumentsSection/redux/documentSectionSlice";
import studentTopUpReducer from "components/CMS/CMSContent/TopUpTabContent/redux/topUpSlice";

export default combineReducers({
  cmsLayout: CMSLayoutReducer,
  studentDetails: StudentDetailsReducer,
  cosignerDetails: CosignerDetailsReducer,
  otherDetails: otherDetailsSlice,
  summary: SummaryContentSlice,
  camSection: CAMSectionSlice,
  documentRequest: DocumentRequestReducer,
  documentSection: DocumentSectionReducer,
  studentTopUp: studentTopUpReducer,
});
