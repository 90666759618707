import { toast } from "react-toastify";
import { call, put, takeLatest, select, all } from "redux-saga/effects";
import { mutateReuploadDocumentsData } from "./documentRequestHelper";
import {
  addDocumentRequest,
  cancelDocumentRequest,
  fetchReuploadDocuments,
  removeRequestedDocument,
  sendDocumentsReuploadToStudent,
  updateRequestedReuploadDocuments,
  updateReuploadDocumentsDetails,
  updateStudentUploadedCategories,
  updateStudentUploadedDocuments,
} from "./documentRequestSlice";
import { errorMessages } from "../../../../../lib/utils/constants";
import { fetchSummaryDetails } from "../../SummaryTabContent/redux/SummaryContentSlice";
import { fetchAllocatedApplicationList } from "../../../CMSLayout/redux/CMSLayoutSlice";
import {
  requestAddToDocumentRequestBucket,
  requestCancelDocumentRequest,
  requestFetchReuploadDocuments,
  requestRemoveRequestedDocument,
  requestSendDocumentsReuploadToStudent,
} from "./documentRequestApi";
import { UserDetailsTypes } from "../../../shared/CMSConstants";

const getStore = (state) => state?.cms;

function* handleFetchReuploadDocuments(action) {
  try {
    const res = yield call(requestFetchReuploadDocuments, action?.payload);
    if (res?.data?.success) {
      const [
        studentDocuments,
        cosignerDocuments,
        extraCosignerDocuments,
        otherDocuments,
        requestedDocuments,
        uploadedDocuments,
        uploadedCategories,
      ] = mutateReuploadDocumentsData(res);
      yield all([
        put(updateReuploadDocumentsDetails(requestedDocuments)),
        put(updateStudentUploadedDocuments(uploadedDocuments)),
        put(updateStudentUploadedCategories(uploadedCategories)),
        put(
          updateRequestedReuploadDocuments({
            studentDocuments,
            cosignerDocuments,
            extraCosignerDocuments,
            otherDocuments,
          })
        ),
      ]);
    } else {
      toast.error(res?.data?.message || errorMessages?.SOMETHING_WENT_WRONG);
    }
  } catch (error) {
    console.error(error);
    toast.error(errorMessages?.FAILED_TO_FETCH);
  }
}

function* handleSendDocumentsReuploadToStudent(action) {
  try {
    const res = yield call(
      requestSendDocumentsReuploadToStudent,
      action?.payload?.slug
    );
    if (res?.data?.success) {
      yield put(
        fetchSummaryDetails({
          applicationSlug: action?.payload?.slug,
          detailsType: UserDetailsTypes.APPLICATION_SUMMARY,
        })
      );
      yield put(
        fetchAllocatedApplicationList({
          casetype: action?.payload?.category?.toUpperCase(),
          pagenumber: action?.payload?.currentPage,
        })
      );
    } else {
      toast?.error(res?.data?.message || errorMessages?.SOMETHING_WENT_WRONG);
    }
  } catch (error) {
    console.error(error);
    toast?.error(errorMessages?.FAILED_TO_FETCH);
  }
}

function* handleRemoveRequestedDocument(action) {
  try {
    const res = yield call(
      requestRemoveRequestedDocument,
      action?.payload?.slug,
      action?.payload?.requestId
    );
    if (!res?.data?.success) {
      toast.error(res?.data?.message || errorMessages?.SOMETHING_WENT_WRONG);
    } else {
      const [
        studentDocuments,
        cosignerDocuments,
        extraCosignerDocuments,
        otherDocuments,
        requestedDocuments,
        uploadedDocuments,
        uploadedCategories,
      ] = mutateReuploadDocumentsData(res);
      yield all([
        put(updateReuploadDocumentsDetails(requestedDocuments)),
        put(updateStudentUploadedDocuments(uploadedDocuments)),
        put(updateStudentUploadedCategories(uploadedCategories)),
        put(
          updateRequestedReuploadDocuments({
            studentDocuments,
            cosignerDocuments,
            extraCosignerDocuments,
            otherDocuments,
          })
        ),
      ]);
    }
    toast.success("Removed Document from Request bucket");
  } catch (error) {
    console.error(error);
    toast?.error(errorMessages?.FAILED_TO_FETCH);
  }
}

function* handleCancelDocumentRequest(action) {
  try {
    const res = yield call(
      requestCancelDocumentRequest,
      action?.payload?.slug,
      action?.payload?.data
    );
    if (res?.data?.success) {
      toast?.success(
        "Cleared the request bucket. Please ensure application is in Sent to Credit stage"
      );
      yield all([
        put(updateReuploadDocumentsDetails({})),
        put(
          updateRequestedReuploadDocuments({
            studentDocuments: [],
            cosignerDocuments: [],
            extraCosignerDocuments: [],
            otherDocuments: [],
          })
        ),
      ]);
      yield put(
        fetchSummaryDetails({
          applicationSlug: action?.payload?.slug,
          detailsType: UserDetailsTypes.APPLICATION_SUMMARY,
        })
      );
      yield put(
        fetchAllocatedApplicationList({
          casetype: action?.payload?.category?.toUpperCase(),
          pagenumber: action?.payload?.currentPage,
        })
      );
    } else {
      toast?.error(res?.data?.message || errorMessages?.SOMETHING_WENT_WRONG);
    }
  } catch (error) {
    console.error(error);
    toast?.error(errorMessages?.FAILED_TO_FETCH);
  }
}

function* handleAddDocumentRequest(action) {
  try {
    const res = yield call(
      requestAddToDocumentRequestBucket,
      action?.payload?.slug,
      action?.payload?.data
    );
    const cmsStore = yield select(getStore);
    if (!res?.data?.success) {
      toast.error(res?.data?.message || errorMessages?.SOMETHING_WENT_WRONG);
    } else {
      yield put(
        updateReuploadDocumentsDetails({
          ...cmsStore?.documentRequest?.reuploadDocumentsDetails,
          [`${action?.payload?.data?.keyName}-${action?.payload?.data?.requestCategory}`]:
            res?.data?.data,
        })
      );
      toast?.success("Document added to request bucket");
    }
  } catch (error) {
    console.error(error);
    toast.error(errorMessages?.FAILED_TO_FETCH);
  }
}

function* DocumentRequestSaga() {
  yield takeLatest(fetchReuploadDocuments, handleFetchReuploadDocuments);
  yield takeLatest(
    sendDocumentsReuploadToStudent,
    handleSendDocumentsReuploadToStudent
  );
  yield takeLatest(removeRequestedDocument, handleRemoveRequestedDocument);
  yield takeLatest(cancelDocumentRequest, handleCancelDocumentRequest);
  yield takeLatest(addDocumentRequest, handleAddDocumentRequest);
}
export default DocumentRequestSaga;
