import { createAction, createSlice } from "@reduxjs/toolkit";

const initialState = {
  isLoading: false,
  bankAccounts: null,
  bankProofDocumentDetailsForView: null,
  isViewBankProofDocument: false,
};

export const fetchAllBankAccounts = createAction(
  "bankAccountUpdate/fetchAllBankAccounts"
);

export const activateBankAccount = createAction(
  "bankAccountUpdate/activateBankAccount"
);

export const editBankAccount = createAction(
  "bankAccountUpdate/editBankAccount"
);

export const updateBankStatus = createAction(
  "bankAccountUpdate/updateBankStatus"
);

export const fetchBankProofDocumentDetailsForView = createAction(
  "bankAccountUpdate/fetchBankProofDocumentDetailsForView"
);

const bankAccountSlice = createSlice({
  name: "bankAccountUpdate",
  initialState,
  reducers: {
    updateIsLoading(state, action) {
      return { ...state, isLoading: action.payload };
    },
    updateBankAccounts(state, action) {
      return { ...state, bankAccounts: action.payload };
    },
    updateIsViewBankProofDocument(state, action) {
      return { ...state, isViewBankProofDocument: action.payload };
    },
    updateBankProofDocumentDetailsForView(state, action) {
      return { ...state, bankProofDocumentDetailsForView: action.payload };
    },
  },
});

export const {
  updateIsLoading,
  updateBankAccounts,
  updateBankProofDocumentDetailsForView,
  updateIsViewBankProofDocument
} = bankAccountSlice.actions;
export default bankAccountSlice.reducer;
