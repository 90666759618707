import { toast } from "react-toastify";
import { errorMessages } from "lib/utils/constants";
import { call, put, takeEvery, takeLatest } from "redux-saga/effects";
import {
  updateIsLoading,
  saveTopUpDetails,
  updateIsEditTopUp,
  updateTopUpDetails,
  fetchStudentTopUpDetails,
  approveStudentTopUpRequest,
} from "components/CMS/CMSContent/TopUpTabContent/redux/topUpSlice";
import {
  requestTopUpDetails,
  requestTopUpApproval,
  saveAndUpdateTopUpDetails,
} from "components/CMS/CMSContent/TopUpTabContent/redux/topUpApis";

function* handleFetchStudentDetails(action) {
  yield put(updateIsLoading(true));
  const slug = action?.payload?.slug;
  try {
    const res = yield call(requestTopUpDetails, slug);
    if (res?.data?.success) {
      yield put(updateTopUpDetails(res?.data?.data));
      yield put(updateIsEditTopUp(false));
    } else {
      toast.error(res?.data?.message || errorMessages?.SOMETHING_WENT_WRONG);
    }
  } catch (err) {
    console.error(err);
    toast.error(errorMessages?.SOMETHING_WENT_WRONG);
  } finally {
    yield put(updateIsLoading(false));
  }
}

function* handleStudentTopUpApprove(action) {
  yield put(updateIsLoading(true));
  const slug = action.payload?.slug;
  try {
    const res = yield call(requestTopUpApproval, slug);
    if (res?.data?.success) {
      yield put(fetchStudentTopUpDetails({ slug }));
      toast.success("Updated successfully");
    } else {
      toast.error(res?.data?.message || errorMessages?.SOMETHING_WENT_WRONG);
    }
  } catch (error) {
    console.error(error);
    toast.error(errorMessages?.SOMETHING_WENT_WRONG);
  } finally {
    yield put(updateIsLoading(false));
  }
}

function* handleSaveTopUp(action) {
  yield put(updateIsLoading(true));
  const slug = action.payload?.slug;
  try {
    const res = yield call(saveAndUpdateTopUpDetails, {
      data: action.payload?.payload,
      slug,
    });
    if (res?.data?.success) {
      yield put(fetchStudentTopUpDetails({ slug }));
      toast.success("Updated successfully");
    } else {
      toast.error(res?.data?.message || errorMessages?.SOMETHING_WENT_WRONG);
    }
  } catch (error) {
    console.error(error);
    toast.error(errorMessages?.SOMETHING_WENT_WRONG);
  } finally {
    yield put(updateIsLoading(false));
  }
}

function* StudentTopUpDetailsSaga() {
  yield takeLatest(saveTopUpDetails, handleSaveTopUp);
  yield takeEvery(fetchStudentTopUpDetails, handleFetchStudentDetails);
  yield takeLatest(approveStudentTopUpRequest, handleStudentTopUpApprove);
}
export default StudentTopUpDetailsSaga;
