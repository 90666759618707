import { createAction, createSlice } from "@reduxjs/toolkit";

const initialState = {
  requestedReuploadDocuments: {},
  reuploadDocumentsDetails: {},
  studentUploadedDocuments: [],
  studentUploadedCategories: [],
};

export const fetchReuploadDocuments = createAction(
  "documentRequest/fetchReuploadDocuments"
);

export const sendDocumentsReuploadToStudent = createAction(
  "documentRequest/sendDocumentsReuploadToStudent"
);

export const removeRequestedDocument = createAction(
  "documentRequest/removeRequestedDocument"
);

export const cancelDocumentRequest = createAction(
  "documentRequest/cancelDocumentRequest"
);

export const addDocumentRequest = createAction("documentRequest/addDocumentRequest");

const documentRequestSlice = createSlice({
  name: "documentRequest",
  initialState,
  reducers: {
    updateRequestedReuploadDocuments(state, action) {
      return {
        ...state,
        requestedReuploadDocuments: {
          studentDocuments: action?.payload?.studentDocuments,
          cosignerDocuments: action?.payload?.cosignerDocuments,
          extraCosignerDocuments: action?.payload?.extraCosignerDocuments,
          otherDocuments: action?.payload?.otherDocuments,
        },
      };
    },
    updateReuploadDocumentsDetails(state, action) {
      return { ...state, reuploadDocumentsDetails: action?.payload };
    },
    updateStudentUploadedDocuments(state, action) {
      return { ...state, studentUploadedDocuments: action?.payload };
    },
    updateStudentUploadedCategories(state, action) {
      return { ...state, studentUploadedCategories: action?.payload };
    },
  },
});

export const {
  updateRequestedReuploadDocuments,
  updateReuploadDocumentsDetails,
  updateStudentUploadedDocuments,
  updateStudentUploadedCategories,
} = documentRequestSlice.actions;
export default documentRequestSlice.reducer;
