import { isFutureDate, isUndefinedOrNull, isValidDate } from "lib/utils/helper";
import { ValidationFieldTypes } from "components/CMS/shared/CMSConstants";

export const generalFieldValidation = (inputValue) => {
  if (!inputValue) return "This field is required";
};

export const specificFieldValidation = (inputValue, inputType, targetValue) => {
  switch (inputType) {
    case ValidationFieldTypes?.GRE:
      if (!inputValue || Number(inputValue) < 130 || Number(inputValue) > 170)
        return "Please enter a valid GRE score";
      break;
    case ValidationFieldTypes?.GMAT:
      if (!inputValue || Number(inputValue) < 0 || Number(inputValue) > 60)
        return "Please enter a valid GMAT score";
      break;
    case ValidationFieldTypes?.TOEFL:
      if (!inputValue || Number(inputValue) < 1 || Number(inputValue) > 120)
        return "Please enter a valid TOEFL score";
      break;
    case ValidationFieldTypes?.IELTS:
      if (!inputValue || Number(inputValue) < 1 || Number(inputValue) > 9)
        return "Please enter a valid IELTS score";
      break;
    case ValidationFieldTypes?.["10TH"] || ValidationFieldTypes?.["12TH"]:
      if (!inputValue || Number(inputValue) < 0 || Number(inputValue) > 100)
        return "Please enter a valid percentage";
      break;
    case ValidationFieldTypes?.AADHAR:
      if (!inputValue || inputValue?.length !== 12 || isNaN(inputValue))
        return "Please enter a valid Aadhar Number";
      break;
    case ValidationFieldTypes?.CREDITSCORE:
      if (isUndefinedOrNull(inputValue))
        return "Please enter a valid credit score";
      break;
    case ValidationFieldTypes?.EMAIL:
      if (
        !inputValue ||
        !/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(inputValue)
      )
        return "Please enter a valid Email";
      break;
    case ValidationFieldTypes?.NONZERO:
      if (inputValue < 0 || (!inputValue && inputValue !== 0))
        return "Please enter a valid value";
      break;
    case ValidationFieldTypes?.DUOLINGO:
      if (!inputValue || Number(inputValue) < 10 || Number(inputValue) > 160)
        return "Please enter a valid Duolingo score";
      break;
    case ValidationFieldTypes?.GMATTOTAL:
      if (!inputValue || Number(inputValue) < 200 || Number(inputValue) > 800)
        return "Please enter a valid total score";
      break;
    case ValidationFieldTypes?.PHONE:
      if (inputValue?.length !== 13) {
        return "Please enter a valid number (with +91)";
      }
      break;
    case ValidationFieldTypes?.GPA:
      if (Number(inputValue) > Number(targetValue)) {
        return "Please enter a valid GPA score";
      }
      break;
    case ValidationFieldTypes?.MULTI_SELECT_VALUES:
      if (!inputValue || !inputValue?.length) {
        return "This field is required";
      }
      break;
    case ValidationFieldTypes?.INVALID_YEAR:
      const yearInString = inputValue?.toString();
      if (yearInString?.length !== 4) {
        return "Invalid Year";
      }
      break;
    case ValidationFieldTypes?.DATE_OF_BIRTH:
      if (!inputValue) return "Please fill a valid Date";
      const [year, month, day] = inputValue.split("-");
      if (!isValidDate(Number(year), Number(month), Number(day)))
        return "Please enter a valid date in the format dd/mm/yyyy";
      if (isFutureDate(Number(year), Number(month), Number(day)))
        return "Please enter a valid date";
      break;
    case ValidationFieldTypes?.PASSING_YEAR:
      if (!inputValue) return "This field is required";
      if (
        Number(inputValue) > new Date().getFullYear() ||
        Number(inputValue) < 1970 ||
        inputValue?.toString().includes(".")
      )
        return "Please enter a valid year";
    default:
      if (!inputValue) return "This field is required";
  }
};
