import axios from "axios";

export const requestResetFeeDetailsForInschool = (data) =>
  axios.request({
    method: "post",
    url: "/api/route?path=fees-outstanding/in-school/reset-fees-details",
    data,
  });

export const requestResetFeeDetailsForRefi = (data) =>
  axios.request({
    method: "post",
    url: "/api/route?path=fees-outstanding/refi/reset-fees-details",
    data,
  });
