import { convertCamelcaseStringToWords } from "../../../../../lib/utils/helper";
import {
  ApplicantTypes,
  DocumentKeyNameMappings,
  DocumentTypeMappings,
  UserTypes,
} from "../../../shared/CMSConstants";

export const mutateReuploadDocumentsData = (response) => {
  const studentDocuments = [];
  const cosignerDocuments = [];
  const extraCosignerDocuments = [];
  const otherDocuments = [];
  const requestedDocuments = {};
  const uploadedDocuments = [];
  const uploadedCategories = [];
  const docs = response?.data?.data;
  for (const document of docs) {
    if (document?.uploadStatus === "PENDING") {
      document.displayName =
        DocumentKeyNameMappings[document?.keyName]?.displayName ||
        convertCamelcaseStringToWords(document?.keyName);
      requestedDocuments[`${document?.keyName}-${document?.requestCategory}`] =
        document?.requestId;
      switch (document?.requestCategory) {
        case ApplicantTypes?.STUDENT?.key:
          studentDocuments?.push(document);
          break;
        case ApplicantTypes?.COSIGNER?.key:
          cosignerDocuments?.push(document);
          break;
        case ApplicantTypes?.EXTRA_COSIGNER?.key:
          extraCosignerDocuments?.push(document);
          break;
        case ApplicantTypes?.OTHER?.key:
          otherDocuments?.push(document);
          break;
        default:
          return;
      }
    } else {
      uploadedDocuments?.push(
        `${document?.keyName}-${document?.requestCategory}`
      );
      const category =
        DocumentTypeMappings[
          DocumentKeyNameMappings[document?.keyName]?.category
        ]?.label;
      const userType = UserTypes[document?.requestCategory];
      if (document?.keyName === "extraDocument") {
        uploadedCategories?.push("applicationExtraDocuments");
      }
      uploadedCategories?.push(userType + category + "Documents");
    }
  }
  return [
    studentDocuments,
    cosignerDocuments,
    extraCosignerDocuments,
    otherDocuments,
    requestedDocuments,
    uploadedDocuments,
    uploadedCategories,
  ];
};
