import { toast } from "react-toastify";
import { errorMessages } from "lib/utils/constants";
import { call, put, takeLatest } from "redux-saga/effects";
import {
  activateBankAccount,
  editBankAccount,
  fetchAllBankAccounts,
  fetchBankProofDocumentDetailsForView,
  updateBankAccounts,
  updateBankProofDocumentDetailsForView,
  updateBankStatus,
  updateIsLoading,
  updateIsViewBankProofDocument,
} from "components/DisbursementApplicationLeadDetails/BankAccountUpdate/redux/BankAccountUpdateSlice";
import {
  fetchBankAccountList,
  requestActivateBankAccount,
  requestBankApprovalStatusUpdate,
  requestDocumentProofByDocId,
  requestEditBankAccount,
} from "components/DisbursementApplicationLeadDetails/BankAccountUpdate/redux/bankAccountUpdateApis";
import { mutateEditValues } from "components/DisbursementApplicationLeadDetails/BankAccountUpdate/redux/BankAccountUpdateHelper";
import { requestDocumentBlobUrl } from "components/CMS/CMSContent/CAMTabContent/DocumentsSection/redux/documentSectionApi";

function* handleFetchAllBankAccounts(action) {
  yield put(updateIsLoading(true));
  try {
    const userId = action.payload;
    const res = yield call(fetchBankAccountList, userId);
    if (res?.data?.success) {
      yield put(updateBankAccounts(res?.data?.data));
    } else {
      toast.error(errorMessages?.SOMETHING_WENT_WRONG);
    }
  } catch (err) {
    console.error(err);
    toast.error(errorMessages?.SOMETHING_WENT_WRONG);
  } finally {
    yield put(updateIsLoading(false));
  }
}

function* handleActivateBankAccount(action) {
  yield put(updateIsLoading(true));
  try {
    const { bankAccountDetailsId, userId } = action.payload;
    const res = yield call(requestActivateBankAccount, {
      bankAccountDetailsId,
    });
    yield put(updateIsLoading(false));
    if (res?.data?.success) {
      toast.success("Successfully Activated Bank Account");
      yield put(fetchAllBankAccounts(userId));
    } else {
      toast.error(errorMessages?.SOMETHING_WENT_WRONG);
    }
  } catch (err) {
    console.error(err);
    toast.error(errorMessages?.SOMETHING_WENT_WRONG);
  }
}

function* handleEditBankAccount(action) {
  yield put(updateIsLoading(true));
  try {
    const { userId, values } = action.payload;
    const res = yield call(requestEditBankAccount, mutateEditValues(values));
    yield put(updateIsLoading(false));
    if (res?.data?.success) {
      toast.success("Successfully Edited Bank Details");
      yield put(fetchAllBankAccounts(userId));
    } else {
      toast.error(errorMessages?.SOMETHING_WENT_WRONG);
    }
  } catch (err) {
    console.error(err);
    toast.error(errorMessages?.SOMETHING_WENT_WRONG);
  }
}

function* handleUpdateBankStatus(action) {
  yield put(updateIsLoading(true));
  try {
    const { userId, values } = action.payload;
    const res = yield call(requestBankApprovalStatusUpdate, values);
    yield put(updateIsLoading(false));
    if (res?.data?.success) {
      yield put(fetchAllBankAccounts(userId));
    } else {
      toast.error(errorMessages?.SOMETHING_WENT_WRONG);
    }
  } catch (err) {
    console.error(err);
    toast.error(errorMessages?.SOMETHING_WENT_WRONG);
  }
}

function* handleFetchBankProofDocumentDetailsForView(action) {
  yield put(updateIsLoading(true));
  try {
    const documentId = action.payload;
    const {
      data: {
        data: { url, name, password },
        success,
      },
    } = yield call(requestDocumentProofByDocId, documentId);
    if (success) {
      const response = yield call(requestDocumentBlobUrl, url);
      if (response && response.data && response.data.size > 0) {
        const file = new Blob([response.data], {
          type: "application/pdf",
        });
        const blobUrl = URL.createObjectURL(file);
        const fileType = name?.split(".")?.pop();
        yield put(
          updateBankProofDocumentDetailsForView({
            password,
            blobUrl,
            fileType,
          })
        );
        yield put(updateIsViewBankProofDocument(true));
      } else {
        toast.error(errorMessages.FILE_VIEW_FAILED);
      }
    } else {
      toast.error(errorMessages?.FILE_VIEW_FAILED);
    }
  } catch (err) {
    console.log("file view error =", err);
    toast.error(errorMessages?.FILE_VIEW_FAILED);
  } finally {
    yield put(updateIsLoading(false));
  }
}

function* BankAccountUpdateSaga() {
  yield takeLatest(fetchAllBankAccounts, handleFetchAllBankAccounts);
  yield takeLatest(activateBankAccount, handleActivateBankAccount);
  yield takeLatest(editBankAccount, handleEditBankAccount);
  yield takeLatest(updateBankStatus, handleUpdateBankStatus);
  yield takeLatest(
    fetchBankProofDocumentDetailsForView,
    handleFetchBankProofDocumentDetailsForView
  );
}
export default BankAccountUpdateSaga;
