import axios from "axios";

export const requestUserDocuments = (slug) => {
  return axios.request({
    method: "GET",
    url: `/api/route?path=documents/${slug}/allDocs`,
    headers: { "X-API-VERSION": "2" },
  });
};

export const requestDocumentBlobUrl = (url) => {
  return axios.request({
    method: "GET",
    url: `/api/downloadDoc?path=documents/download?url=` + url,
    responseType: "blob",
  });
};

export const uploadTopUpContractAndSave = ({ formData, slug }) => {
  return axios.request({
    method: "post",
    url: `/api/upload?path=borrower-agreement/${slug}/upload-topup-borrower-loan-agreement`,
    data: formData,
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
};

export const generateAndDownloadTopUpContractLetter = ({ slug, endPoint }) => {
  return axios.request({
    method: "GET",
    url: `/api/downloadDoc?path=borrower-agreement/${slug}/${endPoint}-borrower-loan-agreement`,
    responseType: "blob",
  });
};
