import {
  requestApplicationDetails,
  requestApprovalCTAText,
  requestApproveApplication,
  requestDownloadCam,
  requestMaxApprovalLimit,
  requestRejectApplication,
  requestSendApplicationForReview,
} from "components/CMS/shared/CMSApis";
import { toast } from "react-toastify";
import { call, put, takeLatest } from "redux-saga/effects";
import { errorMessages } from "lib/utils/constants";
import {
  approveApplication,
  rejectApplication,
  sendApplicationForReview,
  editApplication,
  downloadCam,
  updateIsDownloadingCam,
  fetchMaxApprovalLimit,
  updateMaxApprovalLimit,
  fetchApprovalCTAText,
  updateApprovalCTAText,
  fetchExtraCosignerAttempt,
  updateExtraCosignerExists,
} from "components/CMS/CMSContent/CAMTabContent/CAMSection/redux/CAMSectionSlice";
import { fetchSummaryDetails } from "components/CMS/CMSContent/SummaryTabContent/redux/SummaryContentSlice";
import router from "next/router";
import { fetchAllocatedApplicationList } from "components/CMS/CMSLayout/redux/CMSLayoutSlice";
import { UserDetailsTypes } from "components/CMS/shared/CMSConstants";
import { saveAs } from "file-saver";

function* handleRejectApplication(action) {
  try {
    const res = yield call(requestRejectApplication, {
      ...action.payload,
    });
    if (res?.data?.success) {
      yield put(
        fetchSummaryDetails({
          applicationSlug: router.query.slug,
          detailsType: UserDetailsTypes.APPLICATION_SUMMARY,
        })
      );
      toast.success(
        "Application Rejected Successfully. Please ensure application is in Credit Rejected stage"
      );
      router.push("/cms/[category]", "/cms/my_cases");
    } else {
      toast.error(res?.data?.message || errorMessages?.SOMETHING_WENT_WRONG);
    }
  } catch (error) {
    console.error(error);
    toast.error(errorMessages?.SOMETHING_WENT_WRONG);
  }
}

function* handleSendApplicationForReview(action) {
  try {
    const res = yield call(requestSendApplicationForReview, {
      ...action.payload,
    });
    if (res?.data?.success) {
      toast.success("Application sent for review");
      yield put(
        fetchSummaryDetails({
          applicationSlug: router.query.slug,
          detailsType: UserDetailsTypes.APPLICATION_SUMMARY,
        })
      );
      yield put(
        fetchAllocatedApplicationList({
          casetype: router.query.category?.toUpperCase(),
          pagenumber: 0,
        })
      );
      router.push("/cms/[category]", "/cms/my_cases");
    } else {
      toast.error(res?.data?.message || errorMessages?.SOMETHING_WENT_WRONG);
    }
  } catch (error) {
    console.error(error);
    toast.error(errorMessages?.SOMETHING_WENT_WRONG);
  }
}

function* handleEditApplication(action) {
  try {
    const res = yield call(requestSendApplicationForReview, {
      ...action.payload,
    });
    if (res?.data?.success) {
      toast.success("Application moved to CAM_REVIEW for editing.");
      yield put(
        fetchSummaryDetails({
          applicationSlug: router.query.slug,
          detailsType: UserDetailsTypes.APPLICATION_SUMMARY,
        })
      );
      yield put(
        fetchAllocatedApplicationList({
          casetype: router.query.category?.toUpperCase(),
          pagenumber: 0,
        })
      );
    } else {
      toast.error(res?.data?.message || errorMessages?.SOMETHING_WENT_WRONG);
    }
  } catch (error) {
    console.error(error);
    toast.error(errorMessages?.SOMETHING_WENT_WRONG);
  }
}

function* handleApproveApplication(action) {
  try {
    const res = yield call(requestApproveApplication, {
      ...action.payload,
    });
    if (res?.data?.success) {
      toast.success("Application Approved Successfully");
      yield put(
        fetchSummaryDetails({
          applicationSlug: router.query.slug,
          detailsType: UserDetailsTypes.APPLICATION_SUMMARY,
        })
      );
      yield put(
        fetchAllocatedApplicationList({
          casetype: router.query.category?.toUpperCase(),
          pagenumber: 0,
        })
      );
      router.push("/cms/[category]", "/cms/my_cases");
    } else {
      toast.error(res?.data?.message || errorMessages?.SOMETHING_WENT_WRONG);
    }
  } catch (error) {
    console.error(error);
    toast.error(errorMessages?.SOMETHING_WENT_WRONG);
  }
}

function* handleDownloadCam(action) {
  try {
    yield put(updateIsDownloadingCam(true));
    const response = yield call(requestDownloadCam, action?.payload);
    if (response?.status === 202) {
      response?.data
        .text()
        .then((message) => {
          toast.error(message);
        })
        .catch((error) => {
          throw Error(error);
        });
      return;
    }
    if (response && response?.data && response?.data?.size > 0) {
      const filename =
        response?.headers["content-disposition"]?.split("filename=")[1] ||
        `cam-document-${action?.payload}.pdf`;
      saveAs(response?.data, filename);
      toast.success("CAM downloaded successfully.");
    } else {
      toast.error("Failed to generate file (not found).");
    }
  } catch (error) {
    console.error(error);
    toast.error(errorMessages.SOMETHING_WENT_WRONG);
  } finally {
    yield put(updateIsDownloadingCam(false));
  }
}

function* handleFetchMaxApprovalLimit(action) {
  try {
    const res = yield call(requestMaxApprovalLimit);
    if (res?.data?.success) {
      yield put(updateMaxApprovalLimit(res?.data?.data));
    } else {
      toast.error(res?.data?.message || errorMessages?.SOMETHING_WENT_WRONG);
    }
  } catch (error) {
    console.error(error);
    toast.error(errorMessages?.SOMETHING_WENT_WRONG);
  }
}

function* handleFetchApprovalCTAText(action) {
  try {
    const res = yield call(requestApprovalCTAText, action.payload);
    if (res?.data?.success) {
      yield put(updateApprovalCTAText(res?.data?.data));
    } else {
      toast.error(res?.data?.message || errorMessages?.SOMETHING_WENT_WRONG);
    }
  } catch (error) {
    console.error(error);
    toast.error(errorMessages?.SOMETHING_WENT_WRONG);
  }
}

function* handleFetchCosignerAttempt(action) {
  try {
    const res = yield call(requestApplicationDetails, action.payload);
    if (res?.data) {
      yield put(
        updateExtraCosignerExists(
          !!res?.data?.application?.extraCosignerAttempt
        )
      );
    } else {
      toast.error(res?.data?.message || errorMessages?.SOMETHING_WENT_WRONG);
    }
  } catch (error) {
    console.error(error);
    toast.error(errorMessages?.SOMETHING_WENT_WRONG);
  }
}

function* CAMSectionSaga() {
  yield takeLatest(rejectApplication, handleRejectApplication);
  yield takeLatest(approveApplication, handleApproveApplication);
  yield takeLatest(sendApplicationForReview, handleSendApplicationForReview);
  yield takeLatest(editApplication, handleEditApplication);
  yield takeLatest(downloadCam, handleDownloadCam);
  yield takeLatest(fetchMaxApprovalLimit, handleFetchMaxApprovalLimit);
  yield takeLatest(fetchApprovalCTAText, handleFetchApprovalCTAText);
  yield takeLatest(fetchExtraCosignerAttempt, handleFetchCosignerAttempt);
}
export default CAMSectionSaga;
