import { toast } from "react-toastify";
import { call, put, takeEvery, takeLatest } from "redux-saga/effects";
import {
  requestCmsDetails,
  requestSaveCmsDetails,
} from "../../../../../../shared/CMSApis";
import {
  updateEligibilityDetails,
  updateExtraDetails,
  updateUEOfferDetails,
  updateUpdatedOfferDetails,
  updatePersonalDiscussionDetails,
  updateApprovalDetails,
  fetchOtherDetails,
  saveOtherDetails,
  updateOfacDetails,
} from "./otherDetailsSlice";
import { errorMessages } from "../../../../../../../../lib/utils/constants";
import { fetchAllocatedApplicationList } from "../../../../../../CMSLayout/redux/CMSLayoutSlice";
import { UserDetailsTypes } from "../../../../../../shared/CMSConstants";
import { resetFeeDetails } from "components/Form/OfferDetails/OfferDetailsHelper";

function* handleFetchOtherDetails(action) {
  try {
    const res = yield call(requestCmsDetails, {
      ...action.payload,
    });
    const { detailsType } = action.payload || {};
    if (res?.data?.success) {
      switch (detailsType) {
        case UserDetailsTypes.UEOFFER:
          yield put(updateUEOfferDetails(res?.data?.data));
          break;
        case UserDetailsTypes.EXTRA_DETAILS:
          yield put(updateExtraDetails(res?.data?.data));
          break;
        case UserDetailsTypes.UPDATED_OFFER:
          yield put(updateUpdatedOfferDetails(res?.data?.data));
          break;
        case UserDetailsTypes.ELIGIBILITY:
          yield put(updateEligibilityDetails(res?.data?.data));
          break;
        case UserDetailsTypes.PERSONAL_DISCUSSION:
          yield put(updatePersonalDiscussionDetails(res?.data?.data));
          break;
        case UserDetailsTypes.APPROVAL_DETAILS:
          yield put(updateApprovalDetails(res?.data?.data));
          break;
        case UserDetailsTypes.OFAC_STATUS:
          yield put(updateOfacDetails(res?.data?.data));
          break;
      }
    } else {
      toast.error(res?.data?.message || errorMessages?.SOMETHING_WENT_WRONG);
    }
  } catch (err) {
    console.error(err);
    toast.error(errorMessages?.FAILED_TO_FETCH);
  }
}

function* handleSaveOtherDetails(action) {
  try {
    const res = yield call(requestSaveCmsDetails, {
      ...action.payload,
    });
    if (res?.data?.success) {
      toast.success("Saved Successfully");
      switch (action?.payload?.detailsType) {
        case UserDetailsTypes.ELIGIBILITY:
          yield put(updateEligibilityDetails(res?.data?.data));
          break;
        case UserDetailsTypes.UPDATED_OFFER:
          const {
            applicationSlug,
            data: { loanAmount, currentLoanAmount },
          } = action.payload;
          const payloadForResetFeeDetails = {
            slug: applicationSlug,
            updatedLoanAmount: loanAmount,
            currentLoanAmount,
          };
          yield call(resetFeeDetails, payloadForResetFeeDetails, false, () =>
            toast.error(errorMessages?.SOMETHING_WENT_WRONG)
          );
          yield put(updateUpdatedOfferDetails(res?.data?.data));
          break;
        case UserDetailsTypes.UNIVERSITY_DETAILS:
          yield put(
            fetchAllocatedApplicationList({
              casetype: action?.payload?.category?.toUpperCase(),
              pagenumber: action?.payload?.currentPage,
            })
          );
          break;
      }
    } else {
      toast.error(res?.data?.message || errorMessages?.SOMETHING_WENT_WRONG);
    }
  } catch (err) {
    console.error(err);
    toast.error(errorMessages?.SOMETHING_WENT_WRONG);
  }
}

function* OtherDetailsSaga() {
  yield takeEvery(fetchOtherDetails, handleFetchOtherDetails);
  yield takeLatest(saveOtherDetails, handleSaveOtherDetails);
}

export default OtherDetailsSaga;
