import axios from "axios";

export const fetchBankAccountList = (userId) =>
  axios.request({
    method: "get",
    url: `/api/route?path=bank-account-details/portal/${userId}/fetch-all`,
  });

export const requestBankApprovalStatusUpdate = (data) =>
  axios.request({
    method: "POST",
    url: `/api/route?path=bank-account-details/portal/status-update`,
    data,
  });

export const requestActivateBankAccount = (data) =>
  axios.request({
    method: "POST",
    url: `/api/route?path=bank-account-details/portal/activate`,
    data,
  });

export const requestEditBankAccount = (data) =>
  axios.request({
    method: "POST",
    url: `/api/route?path=bank-account-details/portal/update`,
    data,
  });

export const requestDocumentProofByDocId = (documentId) =>
  axios.get(
    `/api/route?path=bank-account-details/portal/document-proof/${documentId}`
  );
