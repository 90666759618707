import {
  requestCmsDetails,
  requestCollegeAndCampuses,
  requestCoursesOptions,
  requestIntakeSeasons,
  requestSaveCmsDetails,
} from "../../../../../../shared/CMSApis";
import { toast } from "react-toastify";
import { call, put, takeEvery, takeLatest } from "redux-saga/effects";
import {
  fetchStudentDetails,
  updateAcademicDetails,
  updateAddressDetails,
  updateEligibilityDetails,
  updatePersonalDetails,
  updateReferenceDetails,
  updateUniverseDetails,
  updateWorkExperienceDetails,
  updateUpdatedOfferDetails,
  fetchIntakeSeasons,
  updateIntakeSeasons,
  saveStudentDetails,
  fetchCollegeAndCampusPairs,
  updateCollegeAndCampusPairs,
  updateAdditionalComments,
  updateCoursesOptions,
  fetchCoursesOptions,
} from "./studentDetailsSlice";
import { errorMessages } from "../../../../../../../../lib/utils/constants";
import { isEmpty, orderBy } from "lodash";
import { fetchAllocatedApplicationList } from "../../../../../../CMSLayout/redux/CMSLayoutSlice";
import { formatOptions, formatSeasons } from "./studentDetailsHelper";
import { UserDetailsTypes } from "../../../../../../shared/CMSConstants";
import router from "next/router";
import { parseUserPersonalDetails } from "../../../../../../shared/CMSUtils";

function* handleFetchStudentDetails(action) {
  try {
    const res = yield call(requestCmsDetails, {
      ...action.payload,
    });
    const { detailsType } = action.payload;
    if (res?.data?.success) {
      switch (detailsType) {
        case UserDetailsTypes.PERSONAL_DETAILS:
          const parsedPersonalDetails = parseUserPersonalDetails(
            res?.data?.data
          );
          yield put(updatePersonalDetails(parsedPersonalDetails));
          break;
        case UserDetailsTypes.ACADEMIC_DETAILS:
          yield put(updateAcademicDetails(res?.data?.data));
          break;
        case UserDetailsTypes.ADDRESS_DETAILS:
          yield put(updateAddressDetails(res?.data?.data));
          break;
        case UserDetailsTypes.WORK_EXPERIENCE:
          yield put(updateWorkExperienceDetails(res?.data?.data));
          break;
        case UserDetailsTypes.REFERENCES:
          yield put(updateReferenceDetails(res?.data?.data));
          break;
        case UserDetailsTypes.ADDITIONAL_COMMENTS:
          yield put(updateAdditionalComments(res?.data?.data));
          break;
        case UserDetailsTypes.UNIVERSITY_DETAILS:
          yield put(updateUniverseDetails(res?.data?.data));
          break;
      }
    } else {
      toast.error(res?.data?.message || errorMessages?.SOMETHING_WENT_WRONG);
    }
  } catch (err) {
    console.error(err);
    toast.error(errorMessages?.FAILED_TO_FETCH);
  }
}

function* handleFetchIntakeSeasons() {
  try {
    const res = yield call(requestIntakeSeasons, router?.query?.slug);
    if (res?.data?.success) {
      yield put(updateIntakeSeasons(res?.data?.data?.map(formatSeasons)));
    } else {
      toast.error(res?.data?.message || errorMessages?.SOMETHING_WENT_WRONG);
    }
  } catch (err) {
    console.error(err);
    toast.error(errorMessages?.FAILED_TO_FETCH);
  }
}

function* handleSaveStudentDetails(action) {
  try {
    const res = yield call(requestSaveCmsDetails, {
      ...action.payload,
    });
    if (res?.data?.success) {
      toast.success("Saved Successfully");
      switch (action?.payload?.detailsType) {
        case UserDetailsTypes.ELIGIBILITY:
          yield put(updateEligibilityDetails(res?.data?.data));
          break;
        case UserDetailsTypes.UPDATED_OFFER:
          yield put(updateUpdatedOfferDetails(res?.data?.data));
          break;
        case UserDetailsTypes.UNIVERSITY_DETAILS:
          yield put(
            fetchAllocatedApplicationList({
              casetype: action?.payload?.category?.toUpperCase(),
              pagenumber: action?.payload?.currentPage,
            })
          );
          break;
        case UserDetailsTypes.PERSONAL_DETAILS:
          const parsedPersonalDetails = parseUserPersonalDetails(
            res?.data?.data
          );
          yield put(updatePersonalDetails(parsedPersonalDetails));
          break;
      }
    } else {
      toast.error(res?.data?.message || errorMessages?.SOMETHING_WENT_WRONG);
    }
  } catch (err) {
    console.error(err);
    toast.error(errorMessages?.SOMETHING_WENT_WRONG);
  }
}

function* handleFetchCollegeAndCampusPairs() {
  try {
    const res = yield call(requestCollegeAndCampuses);
    if (res?.data) {
      const sortedData = orderBy(
        res.data.filter((_o) => !isEmpty(_o.name)),
        "name",
        "asc"
      );
      yield put(updateCollegeAndCampusPairs(sortedData.map(formatOptions)));
    } else {
      toast.error(res?.data?.message || errorMessages?.SOMETHING_WENT_WRONG);
    }
  } catch (err) {
    console.error(err);
    toast.error(errorMessages?.FAILED_TO_FETCH);
  }
}
function* handleFetchCoursesOptions(action) {
  try {
    const res = yield call(requestCoursesOptions, action?.payload);
    if (res?.data) {
      const sortedData = orderBy(
        res.data.filter((_o) => !isEmpty(_o.name)),
        "name",
        "asc"
      );
      yield put(updateCoursesOptions(sortedData));
    } else {
      toast.error(res?.data?.message || errorMessages?.SOMETHING_WENT_WRONG);
    }
  } catch (err) {
    console.error(err);
    toast.error(errorMessages?.FAILED_TO_FETCH);
  }
}

function* StudentDetailsSaga() {
  yield takeEvery(fetchStudentDetails, handleFetchStudentDetails);
  yield takeLatest(fetchIntakeSeasons, handleFetchIntakeSeasons);
  yield takeLatest(saveStudentDetails, handleSaveStudentDetails);
  yield takeLatest(
    fetchCollegeAndCampusPairs,
    handleFetchCollegeAndCampusPairs
  );
  yield takeLatest(fetchCoursesOptions, handleFetchCoursesOptions);
}
export default StudentDetailsSaga;
