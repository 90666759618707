import cloneDeep from "lodash/cloneDeep";
import {
  DocumentKeyNameMappings,
  DocumentTypeMappings,
} from "../../../../shared/CMSConstants";

export const mutateDocumentsData = (response) => {
  const res = cloneDeep(response);
  const student = res?.data?.data?.profile;
  const cosigner = res?.data?.data?.cosigner;
  const extraCosigner = res?.data?.data?.extraCosigner;
  const userDocuments = {
    studentPersonal: {},
    studentEducational: {},
    studentFinancial: {},
    studentUniversity: {},
    studentOther: {},
    cosignerPersonal: {},
    cosignerFinancial: {},
    cosignerOther: {},
    extraCosignerPersonal: {},
    extraCosignerFinancial: {},
    extraCosignerOther: {},
    studentExtraDocument: {},
    studentTopUpDocuments: {}
  };
  for (const document of student) {
    const mapping = DocumentKeyNameMappings[document?.keyName];
    if (mapping) {
      document.label = mapping?.displayName;
      const details = {
        fileType: document?.name?.split(".")?.pop(),
        url: document?.url,
        password: document?.password
      };
      const category = DocumentTypeMappings[mapping?.category]?.label;
      if (userDocuments[`student${category}`][document?.keyName]) {
        userDocuments[`student${category}`][document?.keyName]?.url?.push(
          details
        );
      } else {
        document.url = [];
        document.url.push(details);
        userDocuments[`student${category}`][document?.keyName] = document;
      }
    }
  }
  for (const document of cosigner) {
    const mapping = DocumentKeyNameMappings[document?.keyName];
    if (mapping) {
      document.label = mapping?.displayName;
      const details = {
        fileType: document?.name?.split(".")?.pop(),
        url: document?.url,
        password: document?.password
      };
      const category = DocumentTypeMappings[mapping?.category]?.label;
      if (userDocuments[`cosigner${category}`][document?.keyName]) {
        userDocuments[`cosigner${category}`][document?.keyName]?.url?.push(
          details
        );
      } else {
        document.url = [];
        document.url.push(details);
        userDocuments[`cosigner${category}`][document?.keyName] = document;
      }
    }
  }
  if (extraCosigner) {
    for (const document of extraCosigner) {
      const mapping = DocumentKeyNameMappings[document?.keyName];
      if (mapping) {
        document.label = mapping?.displayName;
        const details = {
          fileType: document?.name?.split(".")?.pop(),
          url: document?.url,
          password: document?.password
        };
        const category = DocumentTypeMappings[mapping?.category]?.label;
        if (userDocuments[`extraCosigner${category}`][document?.keyName]) {
          userDocuments[`extraCosigner${category}`][
            document?.keyName
          ]?.url?.push(details);
        } else {
          document.url = [];
          document.url.push(details);
          userDocuments[`extraCosigner${category}`][document?.keyName] =
            document;
        }
      }
    }
  }

  return userDocuments;
};
